<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/payments">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Send Payment</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>

            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-3"
                label="Customer:"
                label-for="input-3"
              >
                <multiselect
                  track-by="id"
                  :custom-label="customLabel"
                  label="username"
                  v-model="userId"
                  :options="users"
                  :close-on-select="true"
                  @select="onSelectCustomer"
                  :multiple="false"
                  placeholder="Select customer"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong
                      >{{ option.username }}({{ option.email }})</strong
                    ></template
                  >
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Payable Amount:"
                label-for="input-2"
              >
                <b-form-input
                  id="textarea"
                  disabled=""
                  v-model="payableAmount"
                  placeholder="Payable Amount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Amount:"
                label-for="input-2"
              >
                <b-form-input
                  id="textarea"
                  @keypress="onlyForCurrency"
                  v-model="amount"
                  placeholder="Enter Amount "
                ></b-form-input>
              </b-form-group>

              <br /><br />

              <b-button ref="save" type="submit" variant="primary"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import ApiService from "@/core/services/api.service";
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      userId: null,
      users: [],
      user: null,
      payableAmount: "",
      alertText: "",
      alert: false,
      show: true,
      amount: 0,
    };
  },

  mounted() {
    this.getUsers();
  },
  methods: {
    customLabel({ username, email }) {
      if (!email) {
        return `${username}`;
      }
      return `${username}—(${email})`;
    },

    onSelectCustomer(option) {
      this.payableAmount = option.remaining_amount;
    },

    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.amount.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.amount != null &&
        this.amount.indexOf(".") > -1 &&
        this.amount.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },

    onSubmit(evt) {
      evt.preventDefault();
      try {
        if (this.amount > this.payableAmount) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Please enter less than pending amount";
          return false;
        }
        if (this.userId == null) {
          evt.preventDefault();
          this.alert = true;
          this.alertText = "Customer is Required";
          return false;
        }

        this.addLoaderClasses("save");
        const data = {
          user_id: this.userId.id,
          amount: this.amount,
        };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/payment_store", data, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              this.$router.push({ name: "payments" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert(response);
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    getLogo(logo) {
      return "https://skipshare.acclivousbyte.com/Images/" + logo;
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    getUsers() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}
</style>
